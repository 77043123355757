export const GLASS_TIKI = "GLASS_TIKI";
export const GLASS_CHAMPAGNE = "GLASS_CHAMPAGNE";
export const GLASS_COOP = "GLASS_COOP";
export const GLASS_HIGHBALL = "GLASS_HIGHBALL";
export const GLASS_LOWBALL = "GLASS_LOWBALL";
export const GLASS_MARGARITA = "GLASS_MARGARITA";
export const GLASS_MARTINI = "GLASS_MARTINI";
export const GLASS_MULE = "GLASS_MULE";
export const GLASS_NICKANDNORA = "GLASS_NICKANDNORA";
export const GLASS_REDWINE = "GLASS_REDWINE";
export const GLASS_SHOT = "GLASS_SHOT";